<template>
  <div>
    <!-- <div class="title"><el-button class="primary" plain size="mini">统计报告
      </el-button>
      <el-button class="primary" plain size="mini" @click="onExport">导出
      </el-button>
    </div> -->

    <div style="display: flex;">
      <div class="pie chart-box" v-loading="signLoading">
        <div> 签到率</div>
        <div class="chart" ref="signStatsChart"></div>
      </div>
      <div class="line chart-box" v-loading="viewLoading">
        <div> 页面打开次数</div>
        <div class=" chart" ref="openTimesChart"></div>
      </div>
      <div class="line chart-box" v-loading="shareLoading">
        <div> 分享次数</div>
        <div class=" chart" ref="shareTimesChart"></div>
      </div>
    </div>
    <div style="display: flex;">
      <div class="pie chart-box" v-loading="genderLoading">
        <div>性别</div>
        <div class="chart" ref="genderStatsChart"></div>
      </div>
      <!-- <div class="pie chart-box">
        <div>邀请统计</div>
        <div class="chart"></div>
      </div> -->
      <div class="pie chart-box" v-loading="contentLoading">
        <div>关联内容</div>
        <div class="chart" ref="contentStatsChart"></div>
      </div>
      <div class="pie chart-box" v-loading="userLoading">
        <div>用户类型</div>
        <div class="chart" ref="userStatsChart"></div>
      </div>
    </div>
    <div style="display:flex;">
      <div class="bar chart-box" v-loading="infoCollectLoading">
        <div>信息收集</div>
        <div class="chart" ref="infoCollectStatsChart"></div>
      </div>
    </div>
  </div>
</template>

<script>

import { signStats, genderStats, contentStats, userStats, viewStats, shareStats, infoCollectStats } from "../../api/activity-list/data-statistics.js"
import { getActivity } from "../../api/activity-list/detail.js"
import MyBarChart from "@/modules/organization-admin/assets/echarts/bar-chart";
import MyLineChart from "@/modules/organization-admin/assets/echarts/line-chart";
import MyPieChart from "@/modules/organization-admin/assets/echarts/pie-chart";
export default {
  data() {
    return {
      chartList: [],
      signLoading: true,
      genderLoading: true,
      contentLoading: true,
      infoCollectLoading: true,
      viewLoading: true,
      shareLoading: true,
      userLoading: true
    }
  },
  methods: {
    onExport() {

    },
    //设置图表
    initChart({ obj, type, container }) {
      let chart
      switch (type) {
        case "Pie":
          if (isNaN(obj.data[0].value)) {
            container.innerHTML = "暂无"
            return
          }
          chart = new MyPieChart({
            container,
            ...obj,
          })
          break;
        case "Line":
          chart = new MyLineChart({
            container,
            ...obj,
            // yAxis: { type: 'value', minInterval: 1, min: 0, interval: 1 }
          })
          break;
        case "Bar":
          if (obj.data.length == 0) {
            container.innerHTML = "暂无"
            return
          }
          chart = new MyBarChart({
            container,
            ...obj,
            // yAxis: { type: 'value', minInterval: 1, min: 0, interval: 1 }
          })
          break;
      }
      this.chartList.push(chart)

    }
  },
  destroyChart() {
    this.chartList.forEach(item => { item.dispose() })
    this.chartList = []
  },
  async created() {
    // 签到  
    signStats({ id: this.$route.params.id }).then(({ data }) => {
      let obj = {
        data: [
          { value: Math.round(data.no_sign_count / data.total * 10000) / 100, name: "未签到" },
          { value: Math.round(data.sign_count / data.total * 10000) / 100, name: "签到" }]
      }
      this.signLoading = false
      this.initChart({ obj, type: 'Pie', container: this.$refs.signStatsChart })
    })
    // 性别
    genderStats({ id: this.$route.params.id }).then(({ data }) => {
      let obj = {
        data: [
          { value: Math.round(data.male / data.total * 10000) / 100, name: "男" },
          { value: Math.round(data.female / data.total * 10000) / 100, name: "女" },
          { value: Math.round(data.empty / data.total * 10000) / 100, name: "未填" }]
      }
      this.genderLoading = false
      this.initChart({ obj, type: 'Pie', container: this.$refs.genderStatsChart })
    })
    // 关联内容
    contentStats({ id: this.$route.params.id }).then(({ data }) => {
      let obj = {
        data: [
          { value: Math.round(data.article_count / data.total * 10000 / 100), name: "文章数" },
          { value: Math.round(data.post_count / data.total * 10000 / 100), name: "社区帖子数" },
        ]
      }
      this.contentLoading = false
      this.initChart({ obj, type: 'Pie', container: this.$refs.contentStatsChart })

    })
    // 用户类型
    userStats({ id: this.$route.params.id }).then(({ data }) => {
      let obj = {
        data: [
          { value: Math.round(data.user_count / data.total * 10000) / 100, name: "会员" },
          { value: Math.round(data.non_user_count / data.total * 10000) / 100, name: "游客" },
        ]
      }
      this.userLoading = false
      this.initChart({ obj, type: 'Pie', container: this.$refs.userStatsChart })
    })
    //信息收集
    infoCollectStats({ id: this.$route.params.id }).then(({ data }) => {
      let obj = {
        data: data.map(item => item.count),
        xData: data.map(item => item.name)
      }
      this.infoCollectLoading = false
      this.initChart({ obj, type: 'Bar', container: this.$refs.infoCollectStatsChart })

    })
    //需要活动时间的
    let { data } = await getActivity({ id: this.$route.params.id })
    // 页面打开次数
    viewStats({
      id: this.$route.params.id, start_time: data.activity_start_time
      , end_time: data.activity_end_time
    }).then(({ data }) => {
      let obj = {
        data: data.map(a => a.count),
        xData: data.map(a => a.day_time),
      }
      this.viewLoading = false
      this.initChart({ obj, type: 'Line', container: this.$refs.openTimesChart })
    })
    //信息收集
    shareStats({
      id: this.$route.params.id, start_time: data.activity_start_time
      , end_time: data.activity_end_time
    }).then(({ data }) => {
      let obj = {
        data: data.map(a => a.count),
        xData: data.map(a => a.day_time),
      }
      this.shareLoading = false
      this.initChart({ obj, type: 'Line', container: this.$refs.shareTimesChart })

    })
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-left: 1%;
}

.chart-box {
  height: 200px;
  padding: 20px;
  background-color: #eee;
  margin: 1%;

  .chart {
    height: 140px;
    line-height: 140px;
    text-align: center;
  }
}

.pie {
  width: 23%;



}

.bar {

  width: 48%;

}

.line {
  width: 35.5%;

}</style>