import api from "@/base/utils/request";
// 签到率
export const signStats = data => {
    return api({
      url: "/admin/cyc_activity/ActivityStats/signStats",
      method: "post",
      data
    });
  };
  //性别
  export const genderStats = data => {
    return api({
      url: "/admin/cyc_activity/ActivityStats/genderStats",
      method: "post",
      data
    });
  };
  //内容关联
  export const contentStats = data => {
    return api({
      url: "/admin/cyc_activity/ActivityStats/contentStats",
      method: "post",
      data
    });
  };
  //用户类型
  export const userStats = data => {
    return api({
      url: "/admin/cyc_activity/ActivityStats/userStats",
      method: "post",
      data
    });
  };
  
  //页面打开次数
  export const viewStats = data => {
    return api({
      url: "/admin/cyc_activity/ActivityStats/viewStats",
      method: "post",
      data
    });
  };
  //分享次数
  export const shareStats = data => {

    return api({
      url: "/admin/cyc_activity/ActivityStats/shareStats",
      method: "post",
      data
    });
  };
//信息收集
export const infoCollectStats = data => {
    return api({
      url: "/admin/cyc_activity/ActivityStats/infoCollectStats",
      method: "post",
      data
    });
  };